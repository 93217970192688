import React, { Component } from 'react';
import videoTriggerIcon from './../../images/video-triger-icon.png';
import ModalVideo from 'react-modal-video'

class VideoSection extends Component {
  state = {
    videoUrl: '',
  };

  constructor () {
    super()
    this.state = {
      isOpen: false
    }
    this.openModal = this.openModal.bind(this)
  }

  openModal () {
    this.setState({isOpen: true})
  }
  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-lg-8 offset-lg-2 col-md-10 offset-md-1">
            <div className="section-wrapper text-center">
              <span className="section-sub">Intro</span>
              <h2 className="section-title">What is Tokinfo?</h2>
            </div>
            <div className="howItWork-video-wrapper">
              <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='g5EFgIHDvrw' onClose={() => this.setState({isOpen: false})} />
              <div className="howItWork-video-img">
                <img src="https://i.ytimg.com/vi/g5EFgIHDvrw/maxresdefault.jpg" alt="" />
                <a
                  onClick={this.openModal}
                >
                  <div className="pulsating-circle">
                    <img src={videoTriggerIcon} alt="" />
                  </div>
                </a>
              </div>
              <div className="howItWork-video-link">
                <div
                  className="modal fade"
                  id="exampleModalCenter"
                  tabIndex="-1"
                  role="dialog"
                  aria-labelledby="exampleModalCenterTitle"
                  aria-hidden="true"
                >
                  <div
                    className="modal-dialog modal-dialog-centered"
                    role="document"
                  >
                    <div className="modal-content">
                      <div className="modal-body">
                        <iframe
                          width="930"
                          height="550"
                          src={this.state.videoUrl}
                          frameBorder="0"
                          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                          title="videoIframe"
                        ></iframe>
                      </div>
                      <div className="modal-footer">
                        <button
                          onClick={this.removeVideoUrl}
                          type="button"
                          className="btn btn-secondary"
                          data-dismiss="modal"
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default VideoSection;
