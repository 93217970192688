import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

import Navbar from './../components/NavbarComponent/Navbar';
import Slider from './../components/SliderComponent/Slider.js';
import FeatureList from './../components/FeatureComponent/Feature.js';
import HowItWork from './../components/HowItWorkComponent/HowItWork.js';
import VideSection from './../components/VideoComponent/VideoComponent';
import AppFeature from './../components/AppComponent/AppComponent';
import Footer from './../components/FooterComponent/Footer';

class Apps extends Component {
  render() {
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Tokinfo | Express Anything With Numbers</title>
        </Helmet>
        <Navbar />
        <div className="sliderSection" id="home">
          <div className="bg-shape-holder">
            <span className="bg-round-shape-one"></span>
            <span className="bg-round-shape-two"></span>
          </div>

          <Slider />
          <div className="bottom-bg"></div>
        </div>

        <div className="video-section" id="intro">
          <VideSection />
        </div>

        <div className="feature-section" id="featureSection">
          <FeatureList />
        </div>

        <div className="howItWork-section" id="howItWorkSection">
          <HowItWork />
        </div>

        <div className="appFeatureSection">
          <AppFeature />
        </div>
        <div className="footer">
          <Footer />
        </div>
      </div>
    );
  }
}

export default Apps;
