import React, { Component } from 'react';
import DomainSearch from './../DomainSearchComponent/DomainSearch.js';

class Slider extends Component {
  render() {
    return (
      <div>
        <div className="hero-social-wrapper">
          <ul className="list-unstyled">
            <li>
              <a href="https://facebook.com/tkn.fo" target="_blank">
                <i className="fa fa-facebook"></i>
              </a>
            </li>
            <li>
              <a href="https://twitter.com/tkn_fo" target="_blank">
                <i className="fa fa-twitter"></i>
              </a>
            </li>
            {/* <li>
							<a href="#" target="_blank"><i className="fa fa-linkedin"></i></a>
						</li>
						<li>
							<a href="#" target="_blank"><i className="fa fa-pinterest-p"></i></a>
						</li> */}
          </ul>
          <p>Follow Us</p>
        </div>

        <div className="hero-section">
          <DomainSearch />
        </div>
      </div>
    );
  }
}

export default Slider;
