import React, { Component } from 'react';
import axios from 'axios';
import Recaptcha from 'react-google-invisible-recaptcha';
import nl2br from 'react-nl2br';
import { tokenFormat } from './../helpers';

//Icon Import
import generateIcon from './../../images/generate-icon.svg';
import revealIcon from './../../images/reveal-icon.svg';
import shareIcon from './../../images/share.svg';
import copyIcon from './../../images/copy.svg';
// import linkIcon from './../../link.svg'
import starIcon from './../../images/star.svg';
import { stringify } from 'querystring';

class DomainSearch extends Component{

	state = {
		generateToken: '',
		generateReveal: '',
		generateTokenCode: '',
		generateRevealCode: '',
		copyTextShow: false,
		copyTextShow2: false,
		copySuccess: false,
		copyTextAlert: false,
		copyTextAlert2: false,
		isTokenShareOption: false,
		shareLinkCopySuccess: false,
		shareLinkCopyAler: false,
		tokenSaveSelectOption: '48',
		gRecaptchaResponse: '',
		firstRead: false,
		loading: false,
		loadingTwo: false,
		linkShareCode: '',
		urlToken: '',
		submitMode: '', //'generate' / 'reveal'
		hasToken: false,
		errorAlert: false
	}

	componentDidMount(){
		const url = new URLSearchParams(window.location.search);

		if(url.has('token')){
			let urlToken = url.get('token')
			this.setState({
				hasToken: true,
				generateReveal: urlToken
			})
		}else{
			this.setState({
				generateReveal: ''
			})
		}
	
	}
	


	_generateToken = () => {
		let generateTokenData = {
			text:this.state.generateToken,
			gRecaptchaResponse: this.state.gRecaptchaResponse,
			deleteAfterHours: this.state.tokenSaveSelectOption,
			deleteOnFirstRead: this.state.firstRead
		}
		axios.post('https://api.tokinfo.com/tokens/generate', generateTokenData)
			.then((response) => {
				
				const generateTokenCode = tokenFormat(response.data.id);
				this.setState({
					linkShareCode: 'https://tkn.fo/'+response.data.id,
					generateTokenCode: generateTokenCode,
					loading: false
				});

				this.setState({
				gRecaptchaResponse: ''
				});
				this.recaptcha.reset();
			})
			.catch((error) => {
				console.log(error);
			});
		this.setState({
			copyTextShow: true
		})
	}

	_revealToken = () => {
		let revealCode = tokenFormat(this.state.generateReveal);

		let revealTokenData = {
			token: revealCode,
			gRecaptchaResponse: this.state.gRecaptchaResponse
		}
		axios.post('https://api.tokinfo.com/tokens/reveal', revealTokenData )
			.then((response) => {
        		this.recaptcha.reset();
				if (!response.data || !response.data.text) {
					this.setState({
						errorAlert: true,
						copyTextShow2: false
					})
				} else {
					this.setState({
						generateRevealCode: response.data.text,
						loadingTwo: false,
						gRecaptchaResponse: '',
						errorAlert: false
					})
				}
			})
			.catch((error) => {
				this.setState({
					errorAlert: true,
					copyTextShow2: false
				})
				this.recaptcha.reset();
			});
	}


	onResolved = () => {
		this.setState({
			gRecaptchaResponse: this.recaptcha.getResponse(stringify())
		})
		if (this.state.submitMode == 'reveal') {
			this.setState({
				copyTextShow2: true,
			})
			this._revealToken();
		} else {
      		this._generateToken();
		}
	}

	firstReadHandler = () => {
		if(this.state.firstRead === false){
			this.setState({
				firstRead: true
			})
		}else{
			this.setState({
				firstRead: false
			})
		}
	}

	tokenSaveTimeHandler = (changeEvent) => {
		this.setState({
			tokenSaveSelectOption: changeEvent.target.value
		})
	}


  	onCopy() {
    	this.setState({
    		copied: true
    	});
	  }
	  
	tokenShareOtpionHandler = () => {
		this.setState({
			isTokenShareOption: true
		});
	}

	changeHandler = (e) => {
		this.setState({
			[e.target.name]: e.target.value
		})
	}

	generateTokenSubmit = (e) => {
		e.preventDefault();
		this.setState({
			submitMode: 'generate'
		});
		if(this.state.generateToken === ''){
			alert('Enter your secret text and Click on the Button to Generate a TOKEN.')
		}else{
			this.setState({
				loading: true
			})
			this.recaptcha.execute();
		}
		
	}

	generateRevealSubmit = (e) => {
		e.preventDefault();
		this.setState({
			submitMode: 'reveal'
		});
		if(this.state.generateReveal === ''){
			alert('Enter your secret TOKEN and click on the Button to get the secret info.')
		}else {
			this.setState({
				loadingTwo: true
			})
      		this.recaptcha.execute();
    	}
	}

	copyToClipboard = () => {
		let textField = document.createElement('textarea');
	    textField.innerHTML = tokenFormat(this.state.generateTokenCode);
	    document.body.appendChild(textField)
	    textField.select()
	    document.execCommand('copy')
	    textField.remove();

	    this.setState({
	    	copySuccess:true,
	    	copyTextAlert: true
	    }); 

	    setTimeout(() => { this.setState({
  			copyTextAlert: false
  		})}, 1000);
  	}

  	copyToClipboard2 = () => {
		  let textField = document.createElement('textarea')
	    textField.innerHTML = this.state.generateRevealCode;
	    document.body.appendChild(textField)
	    textField.select()
	    document.execCommand('copy')
	    textField.remove();

	    this.setState({
	    	copySuccess:true,
	    	copyTextAlert2: true
	    });

	    setTimeout(() => { 
	    	this.setState({
  				copyTextAlert2: false
  			});
  		}, 1000);
	  }
	  
	copyShareLink = () => {
		let textField = document.createElement('textarea');
		textField.innerText = this.state.linkShareCode;
	    document.body.appendChild(textField)
	    textField.select()
	    document.execCommand('copy')
		textField.remove();

	    this.setState({
	    	shareLinkCopySuccess:true,
	    	shareLinkCopyAler: true
	    }); 

	    setTimeout(() => { this.setState({
			shareLinkCopySuccess: false
  		})}, 1000);
	}
	  

	render(){

		return(
			<div>

				<div className="hero-title">
					<h2>Express Anything <br /> With Numbers</h2>
				</div>

				<div className="url-short-wrapper">
						
					<div className="generate-token-wrapper short-wrapper">

						<form className="generate-token-form-wrapper" onSubmit={this.generateTokenSubmit.bind(this)}>
							<div className="generate-token-form-icon-wrapper">
								<img src={generateIcon} alt="" />
							</div>
							<h3 className="generate-token-form-title">generate token</h3>
							<div className="form-group domainShortTextarea">
								<textarea 
									type="text" 
									className="form-control domainShortInput " 
									name="generateToken"
									id="generateToken"
									value={this.state.generateToken}
									onChange= {this.changeHandler}
									placeholder="Enter Your Secret Info" required>
								</textarea>

								<Recaptcha
									ref={ ref => this.recaptcha = ref }
									sitekey="6Lcyj8UUAAAAACD6A3G6UbmoTlKQvI7VARzeC-vT"
									onResolved={ this.onResolved }
									/>

								<div className="token-save-time-wrapper">
									<div className="form-check form-check-inline">
										<p>Duration: </p>
										<input 
											className="form-check-input" 
											type="radio" 
											id="time2" 
											name="saveTimeOption" 
											value="48" 
											checked={this.state.tokenSaveSelectOption === '48'}
											onChange= {this.tokenSaveTimeHandler} />
										<label className="form-check-label" htmlFor="time2">48 Hours</label>
									</div>
									<div className="form-check form-check-inline">
										
										<input 
											className="form-check-input" 
											type="radio" 
											id="time1" 
											name="saveTimeOption" 
											value="24" 
											checked={this.state.tokenSaveSelectOption === '24'}
											onChange= {this.tokenSaveTimeHandler} />
											<label className="form-check-label" htmlFor="time1">24 Hours</label>
										</div>
										
										<div className="form-check form-check-inline">
											<input 
												className="form-check-input" 
												type="radio" 
												id="time3" 
												name="saveTimeOption" 
												value="12" 
												checked={this.state.tokenSaveSelectOption === '12'}
												onChange= {this.tokenSaveTimeHandler} />
											<label className="form-check-label" htmlFor="time3">12 Hours</label>
										</div>

										<div className="form-check firstRead">
											<input 
												className="form-check-input" 
												type="checkbox" 
												value="" 
												id="firstRead"
												// checked={this.state.firstRead === 'firstRead'}
												onChange= {this.firstReadHandler} />
											<label className="form-check-label" htmlFor="firstRead">
												Delete After First Read
											</label>
										</div>
								</div>

								<div className="generate-info-bottom">
									<button 
										type="submit" 
										className="searchBtnSubmit"
										>generate
									</button>
									<p>Enter your secret text and Click on the Button to Generate a TOKEN.</p>
								</div>

								<p className="terms-info mb-0 pt-1">* By Generating Tokens, you agree to Tokinfo's <a href="/terms" target="_blank">Terms of Service</a> and <a target="_blank" href="/privacy">Privacy Policy</a>.</p>

								
									
									
							</div>
							
							
						</form>

						{this.state.copyTextShow === true ? 

							<div className="show-code-wrapper">
								<div className="left">
									<div className="code">

										{this.state.loading === false ? 
											<h2>{tokenFormat(this.state.generateTokenCode)}</h2>
											: 
											<div className="text-center display-none pb-3" id="loadingContainer">
												<div className="lds-ellipsis">
													<div></div>
													<div></div>
													<div></div>
													<div></div>
												</div>
											</div>	
										}
									</div>
									<div className="share-option">
										<div className="copy-token">
											<span  onClick={this.copyToClipboard.bind(this)}><img src={copyIcon} alt="" /></span>
										</div>
										<div className="token-share">
											<span onMouseOver={this.tokenShareOtpionHandler} className="share-icon">
												<img src={shareIcon} alt="" />
											</span>
											{this.state.isTokenShareOption === true ? 
											
												<span className="share-link">
													<input 
														type="text" 
														value= {this.state.linkShareCode} 
														readOnly />
													<span onClick={this.copyShareLink}><img src={copyIcon} alt="" /></span>
												</span>

												:
												<span></span>

											}
											
										</div>
									</div>
									{this.state.copyTextAlert === true ? 
										<p 
											onChange={this.alertShowTime}
											className="alertTextEvent alertTextEventToken"
											>Token Copied</p>
										:
										<p></p>
									}
									{this.state.shareLinkCopySuccess === true ? 
										<p 
											onChange={this.alertShowTime3}
											className="alertTextEvent alertTextEventToken"
											>Link Copied</p>
										:
										<p></p>
									}
								</div>
								<div className="right">
									<img src={starIcon} alt="" />
								</div>
							</div>

							:

							<p className="hideShow"></p>

						}
					</div>

					<div className="generate-reveal-wrapper short-wrapper">	

						<form onSubmit={this.generateRevealSubmit.bind(this)} className="generate-token-form-wrapper">

							<div className="generate-token-form-icon-wrapper">
								<img src={revealIcon} alt="" />
							</div>

							<h3 className="generate-token-form-title">reveal info</h3>
							
							<div className="form-group revelFromGroup">
								<input 
									type="text" 
									name="generateReveal"
									id="generateReveal"
									className="form-control domainShortInput" 
									value={this.state.urlToken === '' ? this.state.generateReveal : this.state.urlToken}
									onChange= {this.changeHandler}
									placeholder="Enter A TOKEN" required />
								<div className="generate-info-bottom">
									<button type="submit" className="searchBtnSubmit">reveal</button>
									{this.state.hasToken === false ? 
										<p>Enter your secret TOKEN and click on the Button to get the secret info.</p>
										
										: 
										<p>Click on the Button to get the secret info.</p>
									}
								</div>

								{this.state.errorAlert === true ? 
									<p className="terms-info mb-0">The token you provided is either invalid or expired (deleted)!</p>
								:
									null
								}
								

								
								
							</div>

							
							
						</form>

						{this.state.copyTextShow2 === true ? 
						
							<div className="reveal-generate-show-wrapper">
								<div className="code sweet-loading">
									{this.state.loadingTwo === false ? 
										<div className="codeRightIcon">{nl2br(this.state.generateRevealCode)}</div>
										: 
										<div className="codeRightIcon">
											<div className="text-center display-none pb-3" id="loadingContainer">
												<div className="lds-ellipsis">
													<div></div>
													<div></div>
													<div></div>
													<div></div>
												</div>
											</div>
										</div>
										
									
									}
								</div>
								<div className="reveal-generate-show-bottom-info">
									<div className="reveal-generate-token-copy">
										<span onClick={this.copyToClipboard2}>
											<img src={copyIcon} alt="" />
										</span>
									</div>
									<div className="reveal-ganerate-token-wrapper">
										<div className="show-code-wrapper">
											<div className="left">
												<div className="code">
													<h2>{tokenFormat(this.state.generateReveal)}</h2>
												</div>
												
											</div>
											<div className="right">
												<img src={starIcon} alt="" />
											</div>
										</div>
										{this.state.copyTextAlert2 === true ? 
											<p 
												onClick={this.alertShowTime2}
												className="alertTextEvent mobileAlert"
												>Copied</p>
											:
											<p className="copyTextAlert2"></p>
										}
									</div>
								</div>


							</div>

							:

							<p className="hideShow"></p>
							
						}

					</div>
					
				</div>
			</div>
			
			
		)
	}
}

export default DomainSearch;