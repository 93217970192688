import React, { Component } from 'react';
import featureImgOne from './feature-img-1.png';
import featureImgTwo from './feature-img-2.png';
import featureImgThree from './feature-img-3.png';
import featureImgFour from './feature-img-4.png'

class Feature extends Component{
	render(){
		return(
			<div className="container">
				<div className="section-wrapper section-style-with-bg text-center">
					<span className="section-sub">Features</span>
					<h2 className="section-title">Why It’s Special?</h2>
				</div>
				<div className="row bg-img">
				<div className="col-lg-3 col-md-4 col-sm-6">
					<div className="feature-wrapper">
						<div className="feature-img">
						<img src={featureImgOne} alt="" />
						</div>
						<div className="feature-content">
							<h3>Text to number!</h3>
							<p>Share info with a number when conveying a text message is cumbersome!</p>
						</div>
					</div>
				</div>

					<div className="col-lg-3 col-md-4 col-sm-6">
						<div className="feature-wrapper">
							<div className="feature-img">
								<img src={featureImgTwo} alt="" />
							</div>
							<div className="feature-content">
								<h3>Privacy!</h3>
								<p>Hide your message from AI, Bots and other spies all over your messaging platforms!</p>
							</div>
						</div>
					</div>

					<div className="col-lg-3 col-md-4 col-sm-6">
						<div className="feature-wrapper">
							<div className="feature-img">
								<img src={featureImgThree} alt="" />
							</div>
							<div className="feature-content">
								<h3>Tiny Messagebox</h3>
								<p>Your message boxes look ugly when you have big messages in it. Just use Tokinfo for big texts and send the short token.</p>
							</div>
						</div>
					</div>

					<div className="col-lg-3 col-md-4 col-sm-6">
						<div className="feature-wrapper">
							<div className="feature-img">
								<img src={featureImgFour} alt="" />
							</div>
							<div className="feature-content">
								<h3>Clear History!</h3>
								<p>Don't keep your sensitive information in message history. Tokinfo messages get deleted automatically after 48 hours!</p>
							</div>
						</div>
					</div>


				</div>
			</div>
		)
	}
}

export default Feature;