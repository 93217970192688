import React, { Component } from 'react';
import { Link } from 'gatsby';
import './AppComponent.scss';
import AppScreenImg from './screen-img.png';
import AppStore from './apple-btn.svg';
import GoogleStore from './google-store.png';

class AppSection extends Component {
  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="app-feature-wrapper">
              <h3>Tokinfo Mobile Apps</h3>
              <p>
                You can get Tokinfo mobile apps on your Android or iPhone. The apps will allow you to use Tokinfo on the go! You can quickly generate info tokens for others or reveal some info which you get from someone else!
              </p>
              <div className="app-store-list">
                <Link
                  target="_blank"
                  to="https://play.google.com/store/apps/details?id=com.tokinfoapp"
                >
                  <img src={GoogleStore} alt="Tokinfo App Google Playstore" />
                </Link>
                <Link
                  target="_blank"
                  to="https://apps.apple.com/us/app/id1517284922"
                >
                <img src={AppStore} alt="Tokinfo App Apple App Store" />
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-6 pr-0">
            <div className="app-screen-wrapper">
              <img src={AppScreenImg} alt="" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AppSection;
