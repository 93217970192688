import React, { Component } from 'react';
import { Link, animateScroll as scroll } from 'react-scroll';
import LogoTop from './../../images/logo1.png';

import './../App.scss';
import './../../sass/index.scss';

class Navbar extends Component {
  state = {
    scrolled: false,
  };

  componentDidMount() {
    window.addEventListener('scroll', () => {
      const isTop = window.scrollY < 100;

      if (isTop !== true) {
        this.setState({
          scrolled: true,
        });
      } else {
        this.setState({
          scrolled: false,
        });
      }
    });
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', function () {});
  }

  scrollToTop = () => {
    scroll.scrollToTop();
  };

  render() {
    return (
      <nav
        className={
          this.state.scrolled === false
            ? 'navbar navbar-expand-lg navbar-light navbar-wrapper'
            : 'navbar navbar-expand-lg navbar-light navbar-wrapper scrolled'
        }
        id="navbar"
      >
        <div className="container">
          <a className="navbar-brand" href="/">
            <img src={LogoTop} alt="" />
          </a>

          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <Link
                  className="nav-link"
                  href="#"
                  to="home"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                >
                  Home
                </Link>
              </li>

              <li className="nav-item">
                <Link
                  className="nav-link"
                  href="#"
                  to="intro"
                  spy={true}
                  smooth={true}
                  offset={0}
                  duration={500}
                >
                  Intro
                </Link>
              </li>

              <li className="nav-item">
                <Link
                  className="nav-link"
                  href="#"
                  to="featureSection"
                  spy={true}
                  smooth={true}
                  offset={0}
                  duration={500}
                >
                  Features
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  href="#"
                  to="howItWorkSection"
                  spy={true}
                  smooth={true}
                  offset={0}
                  duration={500}
                >
                  How it works
                </Link>
              </li>
              {/* <li className="nav-item">
								<Link 
									className="nav-link" 
									href="#" 
									to="contact" 
									spy={true}
									smooth={true}
									offset={0}
									duration={500}
								>contact</Link>
							</li> */}

              {/* <li className="nav-item">
								<button type="button" className="nav-bar-btn">get started</button>
							</li> */}
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}

export default Navbar;
