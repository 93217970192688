import React, { Component } from 'react';
import howItWorkOne from './howItWork-1.svg'
import howItWorkTwo from './howItWork-2.svg'
import howItWorkThree from './howItWork-3.svg'
import arrowImgOne from './arrow1.png'
import arrowImgTwo from './arrow2.png'

class HowItWork extends Component{
	render(){
		return(
			<div className="container">
				<div className="row">
					
					<div className="col-md-12">
						<div className="section-wrapper text-center">
							<span className="section-sub">Steps</span>
							<h2 className="section-title">How It Works</h2>
						</div>
					</div>


				</div>

				<div className="howItWorkMobile">
					<div className="row">

						<div className="col-sm-6">
							<div className="howItWork-wrapper-one workItem">
								<div className="img">
									<img src={howItWorkThree} alt="" />
								</div>
								<div className="body">
									<p>Write or Paste your Secret Text & Generate a Token.</p>
								</div>
							</div>
						</div>

						<div className="col-sm-6">
							<div className="howItWork-wrapper-two workItem">
								<div className="img">
									<img src={howItWorkTwo} alt="" />
								</div>
								<div className="body">
									<p>Send the token number to your Friend.</p>
								</div>
							</div>
						</div>

						<div className="col-sm-6">
							<div className="howItWork-wrapper-three workItem">
								<div className="img">
									<img src={howItWorkOne} alt="" />
								</div>
								<div className="body">
									<p>Your Friend provides the Token & Reveals the secret text.</p>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="howItWorkTopWrapper">

					<div className="howItWork-wrapper-one workItem">
						<div className="img">
							<img src={howItWorkOne} alt="" />
						</div>
						<div className="body">
							<p>Write or Paste your Secret Text & Generate a Token.</p>
						</div>
					</div>

					<div className="arrow-icon-wrapper">
						<div className="arrow-icon">
							<img src={arrowImgOne} alt="" />
						</div>
					</div>

					<div className="howItWork-wrapper-two workItem">
						<div className="img">
							<img src={howItWorkTwo} alt="" />
						</div>
						<div className="body">
							<p>Send the token number to your Friend.</p>
						</div>
					</div>

					<div className="arrow-icon-wrapper">
						<div className="arrow-icon">
							<img src={arrowImgTwo} alt="" />
						</div>
					</div>

					<div className="howItWork-wrapper-three workItem">
						<div className="img">
							<img src={howItWorkThree}  alt="" />
						</div>
						<div className="body">
							<p>Your Friend provides the Token & Reveals the secret text.</p>
						</div>
					</div>

				</div>

				
			</div>
		)
	}
}

export default HowItWork;